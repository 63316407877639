<template>
  <div>404</div>
</template>

<script>
export default {
  name: "Page404",
  computed: {},
};
</script>

<style lang="scss" scoped>
</style>
